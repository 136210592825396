// import * as React from 'react'
import React, { useState, useEffect } from 'react';
import { graphql } from 'gatsby'
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'
import { SliceZone } from '@prismicio/react'
import { PrismicRichText } from '@prismicio/react'
import moment from 'moment';

import { Layout } from '../components/Layout'
import {Events} from '../components/Events'
import { components } from '../slices'
import translate from '../utils/translator'
import SimpleContactForm from '../components/SimpleContactForm';

const RentalTemplate = ({ data }) => {
  console.log('RentalTemplate data: ', data);
  
  const [rental,setRental] = useState([])
  
  useEffect( ()=> {
    const docs = data.allPrismicRentalItem || []
    const now = moment()
    const filtered = docs.nodes.filter(item => {
      console.log('item: ', item)
      const data = item.data
      // console.log('start: ',data.start_time)
      // console.log('finish: ',data.finish_time)
      // const startTime = moment(data.start_time)
      // const finishTime = moment(data.finish_time)
      // return (startTime < now)
      return true;
    })
    console.log('rental: ', filtered)
    setRental(filtered)
  }, [data.allPrismicRentalItem])
  
  if (!data) return null

  const homepage = data.prismicRental || {}
  const seo = {
    title: homepage.data.seo_title,
    description: homepage.data.seo_description,
    keywords: homepage.data.seo_keywords,
    no_robots: homepage.data.no_robots,
  }
  console.log('seo: ', seo)

  const { lang, type, url } = homepage || {}
  const alternateLanguages = homepage.alternate_languages || []
  const activeDoc = {
    lang,
    type,
    url,
    alternateLanguages,
  }
  const topMenu = data.prismicTopMenu || {}
  const footer = data.prismicFooter || {}

  const firstParagraph = (description) => {
    if (description != null) {
      const textLimit = 120
      const text = description.text
      const limitedText = text.substring(0, textLimit)
      if (text.length > textLimit) {
        return (
          <>{`${limitedText.substring(0, limitedText.lastIndexOf(' '))}...`}</>
        )
      }
      return <>{text}</>
    }
    return null
  }

  const CareerItem = ({item}) => {
    console.log('item: ', item)
    const rentalInfo = item.data.body.find(item => item.slice_type === 'rental_info')
    const rentalLink = `/veranstaltungsraum-sindelfingen-mieten/${item.uid}`
    const restDetail = item.data.restaraunt_link.document?.data?.body?.find(item => item.slice_type === 'restaurant_detail')
    const restName = restDetail?.primary?.rest_name
    const restLogo = restDetail?.primary?.logo.url
    const restLink = `/restaurant/${item.data.restaraunt_link.uid}`
    if (!rentalInfo) return null
    console.log('rentalInfo: ', rentalInfo)

    console.log('form_title: ', homepage.data.form_title)

    const firstImgItem = rentalInfo.items[0]
    let image = ""
    if (firstImgItem && firstImgItem.image && firstImgItem.image.url) {
      image = firstImgItem.image.url
    }

    return (
    <div className="rounded-3 border overflow-hidden h-100 d-flex flex-column">
      <a href={rentalLink} className="bg-dark position-relative" style={{height:"240px"}}>
        {image && <img className="image-cover" src={image} />}
      </a>
      <div className="p-4 pt-3 h-100 d-flex flex-column justify-content-between">
        <div>
          <div className="d-flex justify-content-between">
            <div>
              <a href={rentalLink} className="h5 text-decoration-none text-body">{item.data.title}</a>
              {restName && 
                <div className="mt-2"><a href={restLink} className="h6 text-decoration-none text-primary">{restName}</a></div>
              }
            </div>
            {restLogo &&
              <a href={restLink}><img className="rounded-circle bg-light position-relative" style={{height:"64px",width:"64px"}} src={restLogo}  alt='' /></a>
            }
          </div>
          <div className="row gy-2 px-2 mt-2">
            {rentalInfo.primary.area &&
              <div className="col-auto bg-light rounded-3 me-2 p-2 px-3">
                <img className="details-icon me-2" height="20" width="20" src={`/icons/area.svg`} alt=""/>
                <span className="h6 fs-0875 mb-0">{rentalInfo.primary.area} qm</span>
              </div>
            }
            {rentalInfo.primary.persons &&
              <div className="col-auto bg-light rounded-3 p-2 px-3">
                <img className="details-icon me-2" height="20" width="20" src={`/icons/persons.svg`} alt=""/>
                <span className="h6 fs-0875 mb-0">{rentalInfo.primary.persons}</span>
              </div>
            }
          </div>
          <div className="mt-2 text-secondary fs-0875">{firstParagraph(rentalInfo.primary.description)}</div>
        </div>
        <a href={rentalLink} type="button" className="btn btn-primary mt-3">{translate('btn_view_details')}</a>
      </div>
    </div>
  )
  }

  let pageUrl = "";
  if (typeof window !== 'undefined'){
    pageUrl = window.location.pathname;
  }  

  return (
    <Layout topMenu={topMenu.data} footer={footer} activeDocMeta={activeDoc} seo={seo}>
      <div className="bg-light" style={{backgroundImage:"url(" + homepage.data.title_section_image.url + ")",backgroundPosition:"center",backgroundRepeat:"no-repeat",  backgroundSize:"cover"}}>
        <div className="py-lg-4" style={{backgroundColor:"#000000b4",backdropFilter:"blur(3px)"}}>
          <div className="container py-5">
              <h1 className="h3 text-white mb-0">{homepage.data.title}</h1>
          </div>
        </div>
      </div>
      <div className="container py-5">
        <div className="row gy-4">
          <div className="col-12 col-xl-8">
            <div className="row gy-4">
              {
                rental.map((item, index) => (
                  <div key={index} className="col-12 col-lg-6">
                    <CareerItem item={item} />
                  </div>
                ))
              }          
            </div>
          </div>
          <div className="col-12 col-xl-4">
            <div className="sticky-top rounded-3 border overflow-hidden">
              <div className="p-4">
                <h6>{homepage.data.form_title}</h6>
                <div className="no-mb-richtext mb-4">
                  <PrismicRichText field={homepage.data.form_text.richText} />
                </div>
                <SimpleContactForm formId={"sifi-eats-reserve-form"} pageUrl={pageUrl}/>
              </div>
            </div>
          </div>       
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query MyRentalQuery {
    prismicRental {
      _previewable
      data {
        seo_title
        seo_description
        seo_keywords
        no_robots
        title_section_image {
          url
        }
        title
        form_title
        form_text {
          richText
        }
      }
      url
      type
    }

  prismicTopMenu {
    ...TopMenuFragment
  }
  prismicFooter {
    ...FooterFragment
  }

  allPrismicRentalItem(
    # sort: {fields: data___finish_time, order: DESC}
    limit: 10
    skip: 0
    #filter: {
			#data:{
        #finish_time:{gte: $now}
      #}
    #}
  ) {
    nodes {
      _previewable
      uid
      url
      data {
        seo_title
        seo_description
        seo_keywords
        no_robots
        title
        restaraunt_link {
          id
          uid
          link_type
          type
          url
          document {
            ... on PrismicRestaurantDetail {
              id
              data {
                body {
                  ... on PrismicRestaurantDetailDataBodyRestaurantDetail {
                    id
                    slice_type
                    primary {
                      address
                      rest_name
                      logo {
                        url
                      }
                    }
                  }
                }
              }
            }
          }
        }
        body {
          ... on PrismicRentalItemDataBodyRentalInfo {
            id
            slice_type
            primary {
              description {
                text
                richText
              }
              area
              persons
            }
            items {
              image {
                url
              }
            }
          }
        }
      }
    }
  }
}
`

export default withPrismicPreview(RentalTemplate)
